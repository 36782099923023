export default class Header {
  /**
   * Creates an instance of Header.
   */
  constructor() {
    // this.className =  []
    this.Menu();
    this.Sticky();
    this.itLink();
  }
  closest = (el, selector) => {
    let matchesFn, parent;
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
      if (typeof document.body[fn] == 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    })
    while (el) {
      parent = el.parentElement;
      if (parent && parent[matchesFn](selector)) {
        return parent;
      }
      el = parent;
    }
    return null;
  }
  Menu = () => {
    let _target = document.getElementById('iconNav');
    let _lst = document.getElementById('grList');
    let _header = document.getElementById('header');
    const btnSearchHeader = document.querySelector('.bHeader_search .btn');

    if(window.innerWidth > 769){
      let sub = $('.hasSub');
      const subMenu = $('.subMenu');
      const lstSub = document.querySelector('.lstSub');
      if(lstSub) {
        const timeTrans = 0.258;
       subMenu.each((i, el) => {
        el.style.transition = `max-height ${timeTrans}s ease-out`;
       })
        sub.on('mouseover', (e) => {
          const el = $(e.currentTarget).find('.subMenu');
          const height = el.find('.lstSub').get(0).clientHeight;
       
          el.get(0).style.maxHeight = `${height}px`;
        });
        sub.on('mouseout', (e) => {
          const el = $(e.currentTarget).find('.subMenu');
          el.get(0).style.maxHeight = '';
        });
      }
    }

    btnSearchHeader.addEventListener('click', () => {
      if (_header.classList.contains('active')) {
        _header.classList.remove('active');
        _target.classList.remove('active');
        document.body.style.overflow = 'inherit';
        _lst.removeAttribute('style');
      }
    });
    _target.addEventListener('click', () => {
      if (_target.classList.contains('active')) {
        _target.classList.remove('active');
        _header.classList.remove('active');
        document.body.style.overflow = 'inherit';
        _lst.style.height = 0;
      } else {
        _lst.style.top = _header.clientHeight + 'px';
        _target.classList.add('active');
        _header.classList.add('active');
        document.body.style.overflow = 'hidden';
        _lst.style.height = window.innerHeight - this.closest(_target, 'header').clientHeight + 'px';
      }
    });
    const reset = () => {
      if (_target.classList.contains('active')) {
        _lst.style.top = _header.clientHeight + 'px';
        _lst.style.height = window.innerHeight - this.closest(_target, 'header').clientHeight + 'px';
      }
      _lst.style.top = _header.clientHeight + 'px';
      if (_target.classList.contains('active')){
        _target.classList.remove('active');
        _header.classList.remove('active');
        _lst.classList.remove('active');
        document.body.style.overflow = 'inherit';
        _lst.style.height = 0;
      }
    }
    reset();
    window.addEventListener('resize', reset, false);
  }
  Sticky = () => {
    let _header = document.getElementById('header');
     let _body = document.getElementsByTagName('body')[0];
    const _forPc = (top,left) => {
      if (top > 0) {
        _header.classList.add('fixed');
         _header.style.left = -left + "px";
        _body.style.paddingTop = _header.clientHeight + 'px';
      }else{
        _header.classList.remove('fixed');
        _body.style.paddingTop = 0;
        _header.style.left = "";
      }
    }
    const _forSp = (top) => {
      if (top > 0) {
        _header.classList.add('fixed');
        _body.style.paddingTop = _header.clientHeight + 'px';
      }else{
        _header.classList.remove('fixed');
        _body.style.paddingTop = 0;
      }
    }
    const _hand = () => {
      let _top = document.documentElement.scrollTop || document.body.scrollTop;
      let _left = document.documentElement.scrollLeft || document.body.scrollLeft;

      if (window.innerWidth > 769) {
        _forPc(_top,_left);
      }else{
        _forSp(_top);
      }
    }
    window.addEventListener('scroll', _hand, false);
    window.addEventListener('resize', _hand, false);
    window.addEventListener('load', _hand, false);
  }
  itLink = () => {
    const link = window.location.pathname;
    const elemParent = document.querySelectorAll('.bHeader__nav .lstMenu li');
    const elem = document.querySelectorAll('.bHeader_nav .lstMenu li a');
    const item = document.querySelectorAll('.bHeader_grList .lst li a');
    const elemFt = document.querySelectorAll('.bFooter_conts .lst li a');

    elem.forEach((el) => {
      el.addEventListener('click', () => {
        elemParent.forEach((item) => {
          item.classList.remove('active');
        });
        el.parentElement.classList.add('active');
      });
      elemFt.forEach((eft) => {
        eft.addEventListener('click', () => {
          if (eft.getAttribute('href') === el.getAttribute('href')) {
            elemParent.forEach((item) => {
              item.classList.remove('active');
            });
            el.parentElement.classList.add('active');
          }
        });
      });

      const hr = el.getAttribute('href');
      if (hr === link || hr === link.split('#')[0]) {
        el.parentNode.classList.add('active');
      }

      const $parent = $(`a[href="${link}"]`).parents('.item');

      if ($parent.length) {
        $parent.addClass('active');
        if (hr === '/') {
          $parent.removeClass('active');
        }
      }

      const lDetail = link.split('detail')[1];
      const a = el.getAttribute('href').split('/contents/index')[1];
      if (lDetail == a && link !== '/' && lDetail) {
        el.parentNode.classList.add('active');
        if (hr === '/') {
          el.parentNode.classList.remove('active');
        }
      }
    });

    item.forEach((item) => {
      let hrGlist = item.getAttribute('href');
      if (hrGlist === link || hrGlist === link.split('#')[0]) {
        item.parentNode.classList.add('active');
      }
      if (link !== '/' && link.includes(hrGlist)) {
        item.parentNode.classList.add('active');
        if (hrGlist === '/' || hrGlist === '#') {
          item.parentNode.classList.remove('active');
        }
      }

      const lDetail = link.split('contents/detail')[1];
      const a = item.getAttribute('href').split('/contents/index')[1];
      if (lDetail == a && link !== '/' && lDetail) {
        item.parentNode.classList.add('active');
        if (hrGlist === '/' || hrGlist === '#') {
          item.parentNode.classList.remove('active');
        }
      }
    });
  }
}
window.requestAnimFrame = (function(callback) {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function(callback) {
      return window.setTimeout(callback, 1000 / 60);
    };
})();

window.cancelAnimFrame = (function(_id) {
  return window.cancelAnimationFrame ||
    window.cancelRequestAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.webkitCancelRequestAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.mozCancelRequestAnimationFrame ||
    window.msCancelAnimationFrame ||
    window.msCancelRequestAnimationFrame ||
    window.oCancelAnimationFrame ||
    window.oCancelRequestAnimationFrame ||
    function(_id) { window.clearTimeout(id); };
})();
var easingEquations = {
  easeOutSine: function(pos) {
    return Math.sin(pos * (Math.PI / 2));
  },
  easeInOutSine: function(pos) {
    return (-0.5 * (Math.cos(Math.PI * pos) - 1));
  },
  easeInOutQuint: function(pos) {
    if ((pos /= 0.5) < 1) {
      return 0.5 * Math.pow(pos, 5);
    }
    return 0.5 * (Math.pow((pos - 2), 5) + 2);
  }
};

const Anchor = () => {
  let _target = '.anchor-link';
  let _header = document.getElementById('header');
  // let _iconNav = document.getElementById('iconNav');
  // let _nav = document.getElementById('nav');
  let flag_start = false;
  let eles = document.querySelectorAll(_target);
  const stopEverything = () => flag_start = false;
  const _getbuffer = () => {
    let _buffer;
    _buffer = _header.clientHeight;
    window.addEventListener('resize', () => {
    console.log(_buffer);
      _buffer = _header.clientHeight;
    })
    return _buffer;
  }

  const scrollToY = (scrollTargetY = 0, speed = 2000, easing = 'easeOutSine') => {
    let scrollY = window.scrollY || window.pageYOffset;
    let currentTime = 0;
    let time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    function tick() {
      if (flag_start) {
        currentTime += 1 / 60;
        let p = currentTime / time;
        let t = easingEquations[easing](p);
        if (p < 1) {
          window.requestAnimFrame(tick);
          window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else { window.scrollTo(0, scrollTargetY); }
      }
    }
    tick();
  }
  eles.forEach(function(el) {
    el.addEventListener('click', function(e) {
      let next = el.getAttribute('href').split('#')[1];
      if (document.getElementById(next)) {
        flag_start = true;
        e.preventDefault();
        scrollToY((document.getElementById(next).offsetTop - _getbuffer()), 1500, 'easeOutSine');
      }
    })
  });
  const _start = function() {
    let next = window.location.hash.split('#')[1];
    flag_start = true;
    if (next) { scrollToY((document.getElementById(next).offsetTop - _getbuffer()), 1500, 'easeOutSine'); }
  }
  window.addEventListener('load', _start, false);
  document.querySelector("body").addEventListener('mousewheel', stopEverything, false);
  document.querySelector("body").addEventListener('DOMMouseScroll', stopEverything, false);
}

window.addEventListener('DOMContentLoaded', () => {
  new Anchor();
});
