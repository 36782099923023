import 'picturefill';
import { pathToRegexp } from 'path-to-regexp';
import 'smoothscroll-for-websites'; // お好みでdisable
import objectFitImages from 'object-fit-images';
import header from './modules/util/header';
import { matchHeight } from './modules/util/matchHeight';

class VhController {
  constructor() {
    this.HAS_PASSIVE = false
    window.addEventListener('resize', this.onResize, this.isPassive);
    this.onResize();
  }

  onResize = () => {
    const bodyWidth = (document.body && document.body.clientWidth) || 0;
    document.documentElement.style.setProperty('--vw', `${bodyWidth / 100}px`);
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
  isPassive() {
    return this.HAS_PASSIVE ? { passive: true } : false;
  }
}

export default class Main {
  constructor() {
    // アンカーリンク。固定ヘッダー分引くとかにも対応している。
    // 使い方はanchor-link.ts参照。
    new VhController();
    objectFitImages();
  }
}

window.addEventListener('DOMContentLoaded', () => {
  if($(".txtSuccess:not(:animated)").length) {
    $(".txtSuccess:not(:animated)").fadeIn("slow",function(){
      $(this).delay(5000).fadeOut("slow");
    });
  }
  new Main();
  new header();
  new matchHeight();
  new matchHeight('lead_height');
});
