const matchHeight = (function() {
  function matchHeight(selector = 'txt_height') {

    // Reset height of all elements
    this.resetHeight = (items) => {
      for(let i = 0; i < items.length; i++) {
        items[i].style.height = '';
      }
    }

    // Group of elements with the same position
    this.createGroup = (items) => {
      let bodyRect = document.body.getBoundingClientRect();
      let array = []
      let arrTemp = [0]
      let offsetOrg = items[0].getBoundingClientRect().top - bodyRect.top;
      let offsetTemp;
      for(let i = 1; i < items.length; i++) {
        offsetTemp =  items[i].getBoundingClientRect().top - bodyRect.top;
        if(offsetTemp !== offsetOrg) {
          array.push(arrTemp);
          offsetOrg = offsetTemp;
          arrTemp = [i];
        }
        else {
          arrTemp.push(i);
        }
      }
      array.push(arrTemp);

      return array
    }

    this.setHeight= (items,arr) => {
      let maxHeight = items[arr[0]].clientHeight;
      if(arr.length > 1) {
        // get max height
        for(let i = 1; i< arr.length; i++) {
          maxHeight = Math.max(items[arr[i]].clientHeight, maxHeight);
        }

        // set max height
        for(let i = 0; i< arr.length; i++) {
          items[arr[i]].style.height = maxHeight+'px';
        }
      }
    }

    this.handle = (items) => {
      let elmG = [];
      this.resetHeight(items);
      if(items.length > 1) {
        elmG = this.createGroup(items);

        for(let i = 0; i < elmG.length; i++) {
          this.setHeight(items,elmG[i])
        }
      }
    }

    this.init = () => {
      let txt_height = document.getElementsByClassName(selector);
      this.handle(txt_height);
      window.addEventListener('resize',() => {
        this.handle(txt_height);
      })
    }

    this.init();
  }
  return matchHeight
})()

export { matchHeight };
